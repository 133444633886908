/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~open-iconic/font/css/open-iconic-bootstrap.css';

.form-group>label {
    font-weight: 600;
}

.in-button-footer>.col {
    padding-left: 0px;
}

.in-button-footer button:first-child {
    margin-right: 10px;
}

.in-button-footer button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.in-button-footer>.col:last-child {
    text-align: right;
    padding-right: 0px;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}